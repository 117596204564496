import { Dommergrad } from './Dommergrad';
import { Klubb } from './Klubb';

export enum Kjonn {
  Mann = 'M',
  Kvinne = 'K',
}

export type Person = {
  id: number;
  nvfId: string;
  fornavn: string;
  etternavn: string;
  fodselsdato: string;
  kjonn: Kjonn;
  klubb: Klubb;
  dommergrad: Nullable<Dommergrad>;
  dommerkursdato: Nullable<string>;
  dommerkursansvarlig: Nullable<string>;
  trenerKompetanse: Nullable<string>;
  trenerKursdato: Nullable<string>;
  trenerKursansvarlig: Nullable<string>;
};

export type PublicPerson = {
  etternavn: string;
  fornavn: string;
  kjonn: Kjonn;
  klubb: Klubb;
  dommergrad: Nullable<Dommergrad>;
}

export function mapKjonnToReadable(kjonn: Kjonn) {
  if (kjonn === 'M') return 'Mann';

  return 'Kvinne';
}

export function mapPersonIdToReadable(personId: number, personer: Person[]) {
  const person = personer.find(person => person.id === personId);

  if (!person) {
    return 'Ukjent';
  }

  return mapPersonToReadable(person);
}

export function mapPersonToReadable(person: Person) {
  return `${person.fornavn} ${person.etternavn} (${person.nvfId})`;
}
