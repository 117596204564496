export enum TrenerKompetanse {
  Trener1 = 'TRENER_1',
  Trener2 = 'TRENER_2',
  Trener3 = 'TRENER_3',
}

export const trenerKompetanseOptions = [
  { id: TrenerKompetanse.Trener1, label: 'Trener 1' },
  { id: TrenerKompetanse.Trener2, label: 'Trener 2' },
  { id: TrenerKompetanse.Trener3, label: 'Trener 3' },
];
